import { observer } from 'mobx-react';

// Hooks
import { useStores } from 'hooks/use-stores';

// Integrations
import { trackGTMSelectTipAmount } from 'integrations/google-tag-manager/tracking-events';

// Styles
import { StyledTipOptions } from 'components/checkout-modules/CheckoutTipSelect/styles';

// Types
import { PossibleTipSelections } from 'components/tipping/TipOptions';
import { managedFeatures } from 'DynamicValues/DynamicValuesProvider';
import { TipChoice } from 'models/Cart';
import { TipKey, TipType } from 'models/Types';
import styled from 'styled-components';
import { Typography } from 'top-component-library';

// Give each tipOption a unique ID to differentiate
export const getTipOptionKey = (choice: TipChoice, index: number = 0): TipKey => `${choice}-${index}`;

function CheckoutTipSelect() {
  const { checkoutStore, featuresStore, locationStore, tabStore } = useStores();
  const { customer, currencySymbol, currencyCode } = locationStore;
  const { choices } = customer?.app_properties?.tipping ?? {};
  const { selectedCart } = checkoutStore;
  const { tabsEnabled } = featuresStore;
  const { activeConsumerTab } = tabStore;
  const { amountToApplyTipTo, selectedTipOption, selectedTipPercentage } = selectedCart;
  const showDualCheckoutOptions =
    locationStore.customer.enabled_features?.includes(managedFeatures.dualCheckoutOptions) ?? false;

  const onSelectTip = ({ type, choice, amount, percentage }: PossibleTipSelections) => {
    const finalAmount = type === TipType.Percentage ? selectedCart.calculateTipCents(percentage) : amount;

    selectedCart.setTipType(type);
    selectedCart.setTipChoice(choice);
    selectedCart.setUserHasChosenTip(true);
    selectedCart.setTipAmount(finalAmount);

    if (showDualCheckoutOptions) {
      tabStore.setTipType(type);
      tabStore.setTipChoice(choice);
      tabStore.setUserHasChosenTip(true);
      tabStore.setTipAmount(finalAmount);
    }

    trackGTMSelectTipAmount();
  };

  if (!checkoutStore.loaded || !selectedCart || !choices) {
    return null;
  }

  return (
    <>
      <StyledTipOptions
        className=""
        choices={choices}
        selectedTipPercentage={selectedTipPercentage}
        selectedTipOption={selectedTipOption}
        currencySymbol={currencySymbol ?? '$'}
        currencyCode={currencyCode}
        amountToApplyTipTo={amountToApplyTipTo}
        onSelectTip={onSelectTip}
      />
      {tabsEnabled && !activeConsumerTab && !showDualCheckoutOptions && (
        <TabsNote>You’ll be able to select tip for every future order placed on this tab.</TabsNote>
      )}
    </>
  );
}

const TabsNote = styled(Typography.Text)`
  font-weight: 400;
  font-size: 0.875rem;
  opacity: 0.6;
`;

export default observer(CheckoutTipSelect);
