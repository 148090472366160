export const customCheckoutText: { [key: string]: string } = {
  '7c3ca8c4-107f-4e82-a9be-0d8e313e4731': 'Order & Pay (Dino Style!)', // Dinosoaur Cafe Staging Test --> (https://staging.dinosaurcafe.menu/dinocafetabletabsfixed?menu=baaee2b6-35fc-46ef-bee8-ce026a858e0f)
  '222984a7-8858-440b-bc97-a81df9144222': 'Finish and Pay',
  'f45a4dd8-031e-49eb-834b-29abc37b7e43': 'Order and Pay',
  '08e7fb3e-f46d-4f9a-96b2-ed1450cc1d0c': 'Order & Pay',
  'fa1c48df-b3c6-411c-a5fc-dc18f1a4f836': 'Go To Tab',
};

export const skipCustomCheckout = ['fa1c48df-b3c6-411c-a5fc-dc18f1a4f836'];

export const customBogoText: { [key: string]: string } = {
  '7c3ca8c4-107f-4e82-a9be-0d8e313e4731': 'Dino Dino Club',
  'fa1c48df-b3c6-411c-a5fc-dc18f1a4f836': 'Taco Taco Club',
};
